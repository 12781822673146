import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import BlogCard from "../../components/BlogCard/BlogCard";
import { Fade } from "react-reveal";
import { blogsHeader } from "../../portfolio.js";
import "./Blogs.css";
import BlogsImg from "./BlogsImg";
import { style } from "glamor";
import axios from "axios"

function Blogs(props) {
  const theme = props.theme;

  const styles = style({
    backgroundColor: `${theme.accentBright}`,
    ":hover": {
      boxShadow: `0 5px 15px ${theme.accentBright}`,
    },
  });

  const [profile, setProfile] = useState({ ptitle: "", name: "", avtar: "", profileurl: ""})
  const [item, setItem] = useState([])
  const [isloading, setisloading] = useState(false)
  const [error, seterror] = useState(null)

  useEffect(()=> {
    setisloading(true);
    // Get all airlines from api
    // Update airlines in our state 
    axios.get('https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@kanani-nirav')
    .then( resp  => {
      setProfile({ ptitle: resp.data.feed.title, name: "", avtar: resp.data.feed.image, profileurl: resp.data.feed.link});
      const res = resp.data.items; //This is an array with the content. No feed, no info about author etc..
      const posts = res.filter(item => item.categories.length > 0);
      setItem(posts);
      setisloading(false);
    } )
    .catch( resp => { seterror(resp); console.log(resp)})
  }, [])
 
  return (
    <div className="blogs-main">
      <Header theme={theme} setTheme={props.setTheme} />
      <div className="basic-blogs">
        <Fade bottom duration={1000} distance="40px">
          <div className="blogs-heading-div">
            <div className="blogs-heading-img-div">
              <BlogsImg theme={theme} />
            </div>
            <div className="blogs-heading-text-div">
              <h1
                className="blogs-heading-text"
                style={{ color: theme.text }}
              >
                {blogsHeader.title}
              </h1>
              <p
                className="blogs-header-detail-text subTitle"
                style={{ color: theme.secondaryText }}
              >
                {blogsHeader.description}
              </p>
            </div>
          </div>
        </Fade>
      </div>
      <div className="repo-cards-div-main">
        {item.map((repo) => {
          return <BlogCard repo={repo} theme={theme} profile={profile}/>;
        })}
      </div>
      <br />
      {/* <br />
      <br />
      <a
        {...styles}
        className="general-btn"
        href="https://github.com/kananinirav"
      >
        More Projects (Github)
      </a>
      <br />
      <br /> */}
      <Footer theme={props.theme} onToggle={props.onToggle} />
    </div>
  );
}

export default Blogs;
