/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to true if you want to use the splash screen.
  useCustomCursor: false, // Change this to false if you want the good'ol cursor
  googleTrackingID: "G-844C44JWLD",
};

//Home Page
const greeting = {
  title: "Hello 👋.",
  title2: "Nirav Kanani",
  logo_name: "nirav.k()",
  nickname: "nirav ",
  full_name: "Nirav Kanani",
  subTitle:
    "Software Engineer 💻 Passionate About Continuous Growth And Development.",
  resumeLink:
    "https://drive.google.com/file/d/1Le-c5w-WQzy9doDtK4hM12M7ysnDOUU6/view?usp=sharing",
  mail: "mailto:kananinirav0@gmail.com",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/kananinirav",
  linkedin: "https://www.linkedin.com/in/nirav-kanani-1b0a36106/",
  gmail: "kananinirav0@gmail.com",
  gitlab: "https://gitlab.com/kananinirav",
  twitter: "https://twitter.com/kananinirav1",
  instagram: "https://www.instagram.com/kanani_nirav/",
};

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Develop highly interactive Front end / User Interfaces for your web applications",
        "⚡ Building responsive website front end using Ruby On Rails or ReactJS ",
        "⚡ Creating application backend in Ruby On Rails",
        "⚡ Integration of third party services such as Firebase/ AWS / Heroku"
      ],
      softwareSkills: [
        {
          skillName: "Rails",
          fontAwesomeClassname: "vscode-icons:file-type-rails"
        },
        {
          skillName: "Ruby",
          fontAwesomeClassname: "logos:ruby"
        },
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "jQuery",
          fontAwesomeClassname: "simple-icons:jquery",
          style: {
            color: "#0865A6",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "API",
          fontAwesomeClassname: "eos-icons:api-outlined",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Google Data Studio",
          fontAwesomeClassname: "logos:google-data-studio",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Google Analytics",
          fontAwesomeClassname: "logos:google-analytics",
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "Bitbucket",
          fontAwesomeClassname: "logos:bitbucket",
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "simple-icons:python",
          style: {
            color: "#3776AB",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Experience hosting and managing websites",
        "⚡ Experience with Continuous Integration",
      ],
      softwareSkills: [
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Netlify",
          fontAwesomeClassname: "simple-icons:netlify",
          style: {
            color: "#38AFBB",
          },
        },
        {
          skillName: "Heroku",
          fontAwesomeClassname: "simple-icons:heroku",
          style: {
            color: "#6863A6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        // {
        //   skillName: "PostgreSQL",
        //   fontAwesomeClassname: "simple-icons:postgresql",
        //   style: {
        //     color: "#336791",
        //   },
        // },
        // {
        //   skillName: "MongoDB",
        //   fontAwesomeClassname: "simple-icons:mongodb",
        //   style: {
        //     color: "#47A248",
        //   },
        // },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "GitHub Actions",
          fontAwesomeClassname: "simple-icons:githubactions",
          style: {
            color: "#5b77ef",
          },
        },
      ],
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: " Marwadi Engineering College",
      subtitle: "Bachelor Of Engineering In Computer Engineering",
      logo_path: "mefgi.png",
      alt_name: "MEFGI",
      duration: "2015 - 2019",
      descriptions: [
        "⚡ Completed Bachelor of Engineering (BE) from Gujarat Technological University (GTU) with 7.70 Cumulative Grade Point Average (CGPA) ",
        "⚡ I have studied core subjects like Data Structures, DBMS, Networking, Security, etc.",
        "⚡ I have also completed various online courses for Backend , Web , Mobile App Development, etc.",
        "⚡ I have implemented several projects based on what I've leart under my Computer Engineering course. ",
      ],
      website_link: "https://www.marwadiuniversity.ac.in/",
    },
    {
      title: "Krishna Science School",
      subtitle: "Higher Secondary Certificate (HSC)",
      logo_path: "Krishna.png",
      alt_name: "Krishna",
      duration: "2013 - 2015",
      descriptions: [
        "⚡ Completed Higher Secondary Certificate (HSC) from Gujarat Secondary and Higher Secondary Education Board (GSHSEB) With B2 Grade"
      ],
      website_link: "http://krishnainternationalschool.com/",
    },
    {
      title: "Krishna Science School",
      subtitle: "Secondary School Certificate (SSC)",
      logo_path: "Krishna.png",
      alt_name: "Krishna",
      duration: "2013",
      descriptions: [
        "⚡ Completed Secondary School Certificate (SSC) from Gujarat Secondary and Higher Secondary Education Board (GSHSEB) With B1 Grade"
      ],
      website_link: "http://krishnainternationalschool.com/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "AWS Certified Cloud Practitioner",
      subtitle: "AWS Certification",
      logo_path: "aws-certified-cloud-practitioner.png",
      certificate_link:
        "https://www.credly.com/badges/8dfbb4d3-378e-41e9-87e7-5f01e9aca1e7/public_url",
      alt_name: "AWS Certified Cloud Practitioner",
      // color_code: "#2AAFED",
      color_code: "#ffffff",
    },
    {
      title: "JLPT N5",
      subtitle: "Japanese Language Proficiency Test",
      logo_path: "jlpt.png",
      certificate_link:
        "#",
      alt_name: "JLPT N5",
      // color_code: "#2AAFED",
      color_code: "#ffffff",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work Experience",
  description:
    "Experienced Software Engineer with a demonstrated history of working in the computer software industry. Skilled in Ruby on Rails, HTML, CSS, JavaScript, jQuery, PostgreSQL. Strong engineering professional with a Bachelor's Degree in Computer Engineering from Marwadi Engineering College.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work Experience",
      experiences: [
        {
          title: "Software Engineer",
          company: "Tripla Co., Ltd.",
          company_url: "https://tripla.io/",
          logo_path: "tripla.png",
          duration: "Jun 2023 – Present",
          location: "Tokyo, Japan",
          description:
          [ "Designing, coding, testing, and debugging software applications.",
            "Collaborating with the team to identify and address technical challenges and improve overall product performance.",
            'Participating in code reviews to ensure code quality and adherence to best practices.',
            "Conducting system analysis and troubleshooting to identify and fix software defects or performance issues.",
            "Documenting software functionality, design, and processes to ensure proper understanding and maintainability.",
            "Participating in agile development processes, such as sprint planning, stand-ups, and retrospectives, to ensure efficient and effective product development."
          ],
          color: "#0071C5",
        },
        {
          title: "Software Engineer",
          company: "SoInnovators株式会社",
          company_url: "http://soinnovators.com/",
          logo_path: "so.ico",
          duration: "Nov 2019 – Jun 2023",
          location: "Tsukuba, Japan",
          description:
          [ "Work closely with the Project Manager, Client, and Team Lead on change request functions. Increased productivity and problem-solving technics.",
            "I mostly work as a Ruby On Rails developer and train, manage, and provide guidance to junior developers.",
            "Prepared documentation for development, testing, and user.",
            "Attended regular meetings with customers to enhance the application for the purpose of user needs.",
            "Develops new and maintains existing applications."],
          color: "#0071C5",
        },
        {
          title: "Software Engineer",
          company: "Lainnovatorslab",
          company_url: "https://www.lainnovatorslab.com/index.html",
          logo_path: "la.png",
          duration: "May 2019 - Nov 2019",
          location: "Ahmedabad, India",
          description:
          ["It was my first company and from there I started my career I worked mainly as a Ruby on Rails developer",
          "I've learned how to work with a team, how we manage our work, and more.",
          "Develops new and maintains existing applications.",
          "Prepared program-level and user-level documentation.",
          "Interacted with team leader and gathered requirements."],
          color: "#ee3c26",
        },
        {
          title: ".Net Trainee",
          company: "Infinnium",
          company_url: "https://infinnium.com/",
          logo_path: "inf.jpeg",
          duration: "Feb 2019 - Apr 2019",
          location: "Ahmedabad, India",
          description:
          ["I have learned the basics of .Net and C# and created a small API-related project during the training period.",
          "Build APIs and prepare API documentation with senior developers."],
          color: "#4285F4",
        }
      ],
    },
    {
      title: "Community Involvement",
      experiences: [
        {
          title: "Tag Moderator",
          company: "DEV Community,",
          company_url: "https://dev.to/",
          logo_path: "dev.png",
          duration: "Apr 2023 - Present",
          location: "",
          description:
            ["Moderator of the following tags in DEV Community: Ruby(https://dev.to/t/ruby), Rails(https://dev.to/t/rails), Git(https://dev.to/t/git) , Productivity(https://dev.to/t/productivity)",
            "Report spam, abuse, and plagiarism to maintain high-quality content",
            "Welcome new community members and foster an inclusive environment",
            "Instruct users on DEV features to improve user experience and engagement",
            "Discourage promotional abuse to maintain the integrity of the community",
            "Promote quality posts by identifying and highlighting outstanding contributions",
            "Manage post tags to ensure proper categorization and organization of content"],
          color: "#4285F4",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "",
  avatar_image_path: "projects_image.svg",
};

// Projects Page
const blogsHeader = {
  title: "Blogs",
  description:
        `⚡ I don't blog frequently but when I do something awesome, I do try to document it so it can be helpful to others. I write on Medium. If this guide has been helpful to you please share it with others!
        `,
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "Nirav.jpeg",
    description:
      "You can contact me at the places mentioned below. I will try to get back to you as fast as I can. ",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I don't blog frequently but when I do something awesome, I do try to document it so it can be helpful to others. I write on Twitter.",
    link: "https://twitter.com/Harikrushn9",
    avatar_image_path: "blogs_image.svg",
  },
};

const projects = {
  data: [
    {
      id: "0",
      name: "AWS Cloud Practitioner Short Notes",
      url: "https://kananinirav.com/",
      description:
        "This will help you for quick revision before exam. If you are studying for AWS Cloud Practitioner certifications or you already have them but want to have digital notes of what you studied.",
      languages: [
      ],
    },
    {
      id: "0",
      name: "RightOn(HR system)",
      url: "https://right-on.co.jp/",
      description:
        "It's a HR system. This is a flow system where a manager can create dynamic form flow and employees can apply for various application",
      languages: [
        {
          name: "Rails",
          iconifyClass: "vscode-icons:file-type-rails",
        },
        {
          name: "Ruby",
          iconifyClass: "logos:ruby",
        },
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
      ],
    },
    {
      id: "1",
      name: "SHOHIN MASTER",
      url: "https://www.i-office1.net/",
      description:
        "It's a product management application where we can register product, create order to manufacturer, do stock management, order management etc.",
      languages: [
        {
          name: "Rails",
          iconifyClass: "vscode-icons:file-type-rails",
        },
        {
          name: "Ruby",
          iconifyClass: "logos:ruby",
        },
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
      ],
    },
    {
      id: "2",
      name: "Personal-portfolio",
      url: "https://nirav-kanani.netlify.app/",
      description:
        "A Personal Portfolio Website that shows my work and experience. which is hosted on Netlify.",
      languages: [
        {
          name: "ReactJS",
          iconifyClass: "logos-react",
        },
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "SCSS",
          iconifyClass: "vscode-icons:file-type-scss2",
        },
      ],
    },
    {
      id: "3",
      name: "Blog Application",
      url: "https://github.com/kananinirav/Ruby-on-Rails-blog-application",
      description: "A Simple Blog Web Application With User Authentication made using Ruby On Rails ( for learning purposes ).",
      languages: [
        {
          name: "Rails",
          iconifyClass: "vscode-icons:file-type-rails",
        },
        {
          name: "Ruby",
          iconifyClass: "logos:ruby",
        },
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
      ],
    },
    {
      id: "4",
      name: "finance-tracker",
      url: "https://github.com/kananinirav/finance-tracker",
      description:
        "Finance Tracker web app created using the Ruby on Rails. This is app users can create their profiles and track any stock they want (for learning purposes).",
      languages: [
        {
          name: "Rails",
          iconifyClass: "vscode-icons:file-type-rails",
        },
        {
          name: "Ruby",
          iconifyClass: "logos:ruby",
        },
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
      ],
    },
    {
      id: "5",
      name: "message_me",
      url: "https://github.com/kananinirav/message_me",
      description:
        "Real Time Messaging Application using Ruby on Rails (for learning purposes)",
      languages: [
        {
          name: "Rails",
          iconifyClass: "vscode-icons:file-type-rails",
        },
        {
          name: "Ruby",
          iconifyClass: "logos:ruby",
        },
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
      ],
    },
    {
      id: "6",
      name: "Zairyu Card OCR",
      url: "https://github.com/kananinirav/zairyu-OCR",
      description:
        "A simple python code for getting specific details from zairyu card (for learning purposes)",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
      ],
    },
    {
      id: "7",
      name: "Extracting Vocabulary ",
      url: "https://github.com/kananinirav/Extracting-vocabulary-from-a-Japanese-text",
      description:
        "We can get vocabulary from japanese text using Jisho API (for learning purposes)",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
      ],
    },
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  blogsHeader,
  contactPageData,
  projects,
};
