import React from "react";
import "./BlogCard.css";
import { Fade } from "react-reveal";
import { style } from "glamor";
import moment from "moment";

function ToText(node) {
  let tag = document.createElement("div");
  tag.innerHTML = node;
  node = tag.innerText;
  return node;
}

export default function BlogCard({ repo, theme, profile }) {
  function openRepoinNewTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }
  const styles = style({
    color: "rgb(88, 96, 105)",
    backgroundColor: "rgb(255, 255, 255)",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 10px 30px -15px",
    padding: "2rem",
    cursor: "pointer",
    borderRadius: "5px",
    height: "100%",
    transition: "all 0.2s ease-in-out",
    ":hover": {
      boxShadow: `${theme.imageDark} 0 2px 15px`,
    },
  });

  return (
    <div className="grid">
      <Fade bottom duration={2000} distance="40px">
        <div
          {...styles}
          key={repo.title}
          onClick={() => openRepoinNewTab(repo.link)}
          style={{ backgroundColor: theme.projectCard }}
        >
          <div className="cardsmall">
            <div className="cardpost__image" style={{ backgroundImage: `url(${repo.thumbnail})` }}></div>
            <div className="authorimg">
              <a
                href={profile['profileurl']}
                rel="noopener noreferrer"
                target="_blank"
                style={{ backgroundImage: `url(${profile['avtar']})` }}
              ></a>
            </div>
          </div>
          <div className="repo-name-div">
            <p className="repo-name1 cardText" style={{ color: theme.text }}>
              {repo.title}
            </p>
          </div>
          {/* <p className="repo-description" style={{ color: theme.text }}>
            {ToText(repo.description.substring(0,100))+ '...'}
          </p> */}
          <span><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="calendar-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"></path></svg>{moment(repo.pubDate).format("MMM DD, YYYY hh:mm")}</span>
          <br /><br />
          <div className="tags">
          {repo.categories.map((repo) => {
            return (<a href="#"><span className="tag">{repo}</span></a>);
          })}
          </div>
        </div>
      </Fade>
    </div>
  );
}
